import axios from 'axios';
import { deleteCookie, setCookie } from '@/cookies';

export default {
    namespaced: true,

    state: {
        all: [],
        current: null,
    },

    getters: {},

    mutations: {
        add(state, sessions) {
            for (const session of sessions) {
                state.all.push(session)
            }
        },
        set(state, session) {
            state.current = session
        },
    },

    actions: {
        async create({ commit }) {
            try {
                const response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/sessions`);
                commit('set', response.data)
                setCookie('session', response.data.session_id, 10 * 24)
            } catch (error) {
                console.log(error)
            }
        },
        remove({ commit }) {
            commit('set', null)
            deleteCookie('session')
        },
        async fetch({ commit }, id) {
            try {
                const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/sessions/${id}`);
                commit('set', response.data)
                setCookie('session', response.data.session_id, 10 * 24)
            } catch (error) {
                console.log(error)
            }
        },
        async fetchAll({ commit }) {
            try {
                const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/sessions`);
                commit('add', response.data)
            } catch (error) {
                console.log(error)
            }
        },
    },
}
