<template>
    <router-view></router-view>
</template>

<script>
export default {
    name: 'App',

    computed: {
        session() {
            return this.$store.state.sessions.current
        },
    },

    watch: {
        session: {
            immediate: true,
            handler() {
                console.log("SESSION", this.session)
                if (this.session != null) {
                    this.$store.dispatch("topics/fetchAll")
                    this.$store.dispatch("topics/listenCreated", this.session.session_id)
                    this.$store.dispatch("topics/listenUpdated", this.session.session_id)
                    this.$store.dispatch("topics/listenDeleted", this.session.session_id)

                    this.$store.dispatch("counter/listen", this.session.session_id)

                    this.$store.dispatch("messages/fetch")
                    this.$store.dispatch("messages/listenCreated", this.session.session_id)
                    this.$store.dispatch("messages/listenLive", this.session.session_id)
                    this.$store.dispatch("messages/listenUpdated", this.session.session_id)
                    this.$store.dispatch("messages/listenRemoved", this.session.session_id)
                }
            },
        },
    },

    mounted() {
        this.$store.dispatch("settings/listenUpdated")
    }
}
</script>

<style lang="scss">
@import "modern-normalize";
@import "@/assets/style.scss";
//@import "@/assets/_firacode.scss";
@import "@/assets/_variables.scss";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body,
#app {
    min-height: 100vh;
    background: rgba($background, .5);
    max-width: 100%;
    font-family: "Source Sans 3", sans-serif;
}

#app {
    display: flex;
}
</style>
