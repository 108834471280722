import { createRouter, createWebHistory } from "vue-router";
import HomePage from "@/views/HomePage.vue";
import TimestampPage from '@/views/TimestampPage.vue'
import TwitchToken from '@/views/TwitchToken.vue'

import BackofficePage from "@/views/BackofficePage.vue";
import BackofficeDashBoard from "@/views/backoffice/Dashboard.vue"
import BackofficeMessages from "@/views/backoffice/MessagesView.vue"
import BackofficeSettings from "@/views/backoffice/Settings.vue"
import BackofficeTopics from "@/views/backoffice/Topics.vue"

const routes = [
    {
        path: '/',
        component: HomePage,
        name: 'Home',
    },
    {
        path: '/timestamp',
        component: TimestampPage,
        name: 'TimestampPage',
    },
    {
        path: '/backoffice',
        component: BackofficePage,
        children: [
            {
                path: '',
                component: BackofficeDashBoard,
                name: 'Backoffice',
            },
            {
                path: 'messages',
                component: BackofficeMessages,
                name: 'BackofficeMessages',
            },
            {
                path: 'settings',
                component: BackofficeSettings,
                name: 'BackofficeSettings',
            },
            {
                path: 'topics',
                component: BackofficeTopics,
                name: 'BackofficeTopics',
            },
        ],
    },
    {
        path: '/twitch/token',
        component: TwitchToken,
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router
