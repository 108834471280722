<template>
    <div class="add-upload">
        <form @submit.prevent="submitForm" class="add-form">
            <label for="topicName" class="add-label">Name:</label>
            <input class="add-input" type="text" id="topicName" v-model="topicName" required>
            <label for="minutes" class="add-label">Minutes:</label>
            <input class="add-input" type="number" id="minutes" v-model.number="minutes" required>
            <label for="seconds" class="add-label">Seconds:</label>
            <input class="add-input seconds" type="number" id="seconds" v-model.number="seconds" required>
            <button type="submit" class="add-btn">Submit</button>
        </form>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    name: 'UploadTopic',
    data() {
        return {
            topicName: '',
            minutes: 0,
            seconds: 0,
        }
    },
    methods: {
        async submitForm() {
            const formData = {
                topic: this.topicName,
                minutes: this.minutes,
                seconds: this.seconds
            };
            try {
                await axios.post(`${process.env.VUE_APP_BACKEND_URL}/topics`, formData, { headers: this.headers })
                this.topicName = '';
                this.minutes = 0;
                this.seconds = 0;
            } catch (error) {
                console.log(error)
            }
        },
    },
    computed: {
        headers() {
            let headers = {}
            if (this.session) {
                headers.session = this.session.session_id
            }
            return headers
        },
        session() {
            return this.$store.state.sessions.current
        },
    },
    mounted() { }
}
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";

.add-upload {
    width: 100%;
    position: relative;
}

.add-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: .5rem;
}

.add-label {
    color: $text-color;
}

.add-input {
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: $text-color;
    background-color: $bubble-two-color;
    border: 1px solid $bubble-two-color;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.add-input:focus {
    border-color: $color-light_blue;
    outline: 0;
    box-shadow: 0 0 0 0.2rem $color-blue;
}

.add-btn {
    border: none;
    color: $color-blue;
    padding: 0.5rem 1rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    background-color: $bubble-two-color;
    width: 100%;
    bottom: 0;
    width: -webkit-fill-available;
}

.add-btn:hover {
    color: $color-light_blue;
    background: $bubble-color;
}
</style>
