<template>
    <div class="messagesComponent">
        <h2>Messages</h2>
        <MessageComponent v-for="message in messages" :message="message" :key="message.id" />
        <ButtonComponent class="add" @click.prevent="submitMessage">
            Add Message
        </ButtonComponent>
    </div>
</template>

<script>
import axios from 'axios'

import MessageComponent from "@/components/MessageComponent";
import ButtonComponent from "@/components/ButtonComponent";

export default {
    name: 'MessagesComponent',
    components: {
        ButtonComponent,
        MessageComponent,
    },
    data() {
        return {
        }
    },
    methods: {
        async submitMessage() {
            try {
                await axios.post(
                    `${process.env.VUE_APP_BACKEND_URL}/messages`,
                    { message: "" },
                    { headers: this.headers },
                );
            } catch (error) {
                console.error(error);
            }
        },
    },
    computed: {
        headers() {
            let headers = {}
            if (this.session) {
                headers.session = this.session.session_id
            }
            return headers
        },
        messages() {
            return this.$store.state.messages.all
        },
        session() {
            return this.$store.state.sessions.current
        },
    },
    mounted() {
    },
    unmounted() {
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";

.messagesComponent {
    width: 100%;
    max-width: 600px;
    margin: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    h2 {
        line-height: 1;
        margin: 0;
    }

    textarea {
        resize: none;
    }

}
</style>
