<template>
  <div class="editTopicComponent" :class="{ active }" :style="{'--progress': progress}">
    <h2 v-text="index"></h2>

    <input class="editTopicComponent_title" type="text" v-model="topicName" required>

    <label>
      Duration
    </label>

    <div class="duration">
      <input class="edit-input minutes" type="number" id="editMinutes" v-model.number="minutes" required>
      :
      <input class="edit-input seconds" type="number" id="editSeconds" v-model.number="seconds" required>
    </div>

    <ButtonComponent class="delete" @click.prevent="deleteTopic" icon="delete" />

  </div>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent.vue'

import axios from 'axios';

export default {
  name: 'UpdateTopic',

  components: {
    ButtonComponent,
  },

  props: {
    topic: { required: true },
    index: { default: '1' },
  },

  data() {
    return {
      topicName: '',
      minutes: 0,
      seconds: 0,
    };
  },

  methods: {
    async deleteTopic() {
      try {
        await axios.delete(
          `${process.env.VUE_APP_BACKEND_URL}/topics/${this.topic.id}`,
          { headers: this.headers },
        )
      } catch (error) {
        console.error('Error deleting topic:', error);
      }
    },
    async edit() {
      const data = {
        topic: this.topicName,
        minutes: this.minutes,
        seconds: this.seconds
      }
      try {
        await axios.patch(
          `${process.env.VUE_APP_BACKEND_URL}/topics/${this.topic.id}`,
          data,
          { headers: this.headers }
        )
      } catch (error) {
        console.error('Error updating topic:', error)
      }
    }
  },

  computed: {
    active() {
      return this.activeTopic.topic == this.topic
    },
    activeTopic() {
      return this.$store.getters['topics/active']
    },
    progress() {
      if (this.active) {
        return this.activeTopic.topicTime / this.topic.totalMillis
      }
      if (this.activeTopic.pastTopics.includes(this.topic.id)) {
        return 1
      }
      return 0
    },
    headers() {
      let headers = {}
      if (this.session) {
        headers.session = this.session.session_id
      }
      return headers
    },
    session() {
      return this.$store.state.sessions.current
    },
  },

  watch: {
    topicName() {
      this.edit()
    },
    minutes() {
      this.edit()
    },
    seconds() {
      this.edit()
    },
  },

  created() {
    this.topicName = this.topic.topic;
    this.minutes = this.topic.minutes;
    this.seconds = this.topic.seconds;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";

.editTopicComponent {
  --_background-color: var(--background-color, #{$bubble-color});
  display: grid;
  grid-template-rows: 1fr min-content 1fr;
  grid-template-columns: min-content min-content 1fr;
  align-items: center;
  background-color: var(--_background-color);
  border-radius: 5px;
  gap: .5rem;
  padding: .5rem;
  position: relative;
  overflow: hidden;
  grid-template-areas:
    ". label . ."
    "index duration title action"
    ". . . .";

  &.active {
    --background-color: hsl(from var(--color-red) h s calc(l - 10));
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: calc(100% * var(--progress));
    background: hsl(from var(--_background-color) h s calc(l - 20));
    z-index: 1;
  }

  >* {
    grid-row: 2;
    z-index: 2;
  }

  label {
    grid-row: 1;
    grid-area: label;
    text-align: center;
  }

  input {
    background-color: hsl(from var(--_background-color) h s calc(l - 40) / .75);
    color: white;
    padding: 0.375rem .5rem;
    font-size: 1rem;
    line-height: 1.5;
    border: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    outline: none;
  }

  .duration {
    border-radius: 0.25rem;
    background-color: hsl(from var(--_background-color) h s calc(l - 40) / .75);
    grid-area: duration;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;

    input {
      background-color: transparent;
      padding-left: 0;
      padding-right: 0;
      margin: 0 auto 0 0;
      width: 1.5rem;
      appearance: textfield;
      -moz-appearance: textfield;
      text-align: center;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &.minutes {
        margin: 0 0 0 auto;
      }

    }

  }

  &_title {
    grid-area: title;
  }

  .delete {
    grid-area: action;
    background-color: hsl(from var(--_background-color) h s calc(l - 40) / .75);

    &:hover {
      background-color: black;
    }
  }
}

.edit-label {
  color: $text-color;
}

.edit-input:focus {}

.edit-btn {
  border: none;
  color: $color-blue;
  padding: 0.5rem 1rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  background: $bubble-color;
  margin-right: .5rem;
  width: 100%;
}

.edit-btn:hover {
  color: $color-light_blue;
  background-color: $bubble-two-color;
}
</style>
