import { createStore } from "vuex";

import axios from 'axios';
import { socket } from "@/socket";

import counter from "./counter.js";
import messages from "./messages.js";
import settings from "./settings.js";
import topics from "./topics.js";
import sessions from "./sessions.js";

const API_URL = process.env.VUE_APP_API_URL;

export default createStore({
    state: {
        token: null,
        user: null,
        follower: null,
        message: [],
        listVisible: true,
        twitchMessageListening: false,
    },

    getters: {
        totalTime(state) {
            let time = 0
            for (let topic of state.topics.all) {
                time += (topic.minutes * 60 + topic.seconds) * 1000
            }
            return time
        },
    },

    mutations: {
        set_twitch_token(state, token) {
            state.token = token;
        },
        set_twitch_user(state, user) {
            state.user = user;
        },
        set_twitch_follower(state, follower) {
            state.follower = follower;
        },
        set_twitch_message(state, message) {
            state.message = message;
        },
        toggle_list_visibility(state) {
            state.listVisible = !state.listVisible;
        },
        toggle_messages_visibility(state) {
            state.twitchMessagesVisible = !state.twitchMessagesVisible;
        },
        set_twitch_settings(state, settings) {
            state.twitchMessagesVisible = settings.twitchMessagesVisible;
            state.listVisible = settings.twitchFollowers
        },
        twitch_message_listening(state, twitchMessageListening) {
            state.twitchMessageListening = twitchMessageListening
        },
        add_twitch_message(state, message) {
            state.message.push(message)
            while (state.message.length > 3) {
                state.message.shift()
            }
        },
    },
    actions: {
        async getTwitchUser({ commit, state }) {
            try {
                const response = await axios.get(`${process.env.VUE_APP_TWITCH_API}/users`, {
                    headers: {
                        'Client-ID': process.env.VUE_APP_TWITCH_CLIENT_ID,
                        Authorization: 'Bearer b2a5ad0d8e66176'
                        // Authorization: 'Bearer ' + state.token.access_token
                    }
                })
                console.log(state)
                commit('set_twitch_user', response.data.data[0])
            } catch (error) {
                commit('set_twitch_token', null)
                console.log(error)
            }
        },
        async getTwitchToken({ commit, dispatch }) {
            try {
                const response = await axios.get(`${API_URL}twitch/token`);
                commit('set_twitch_token', response.data);
                dispatch('getTwitchUser')
            } catch (error) {
                console.error(error);
            }
        },
        async sendToken(store, token) {
            try {
                await axios.post(`http://localhost/api/twitch/token?${token}`,)

            } catch (error) {
                console.log(error)
            }
        },
        // -----------------------------------
        async getFollowers({ commit, state }) {
            if (!state.listVisible) return
            try {
                const response = await axios.get(`${process.env.VUE_APP_TWITCH_API}/channels/followers`, {
                    headers: {
                        'Client-ID': process.env.VUE_APP_TWITCH_CLIENT_ID,
                        Authorization: 'Bearer b2a5ad0d8e66176'
                        // Authorization: 'Bearer ' + state.token.access_token
                    },
                    params: {
                        broadcaster_id: state.user.id
                    }
                })
                console.log(state)
                commit('set_twitch_follower', response.data.data)
            } catch (error) {
                commit('set_twitch_follower', null)
                console.log(error)
            }
        },
        async getFollowersSettings({ commit }) {
            try {
                const result = await axios.get(`http://localhost/api/settings-twitch_followers`)
                commit('toggle_list_visibility', result.data)
            } catch (error) {
                console.log(error)
            }
        },
        // ------------------------------------
        async getTwitchMessages({ commit }) {
            try {
                const response = await axios.get(`http://localhost/api/twitch-messages`);
                commit('set_twitch_message', response.data)
            } catch (error) {
                commit('set_twitch_message', null)
                console.log(error)
            }
        },
        async toggleListVisibility({ commit, state }) {
            try {
                const result = await axios.post(`http://localhost/api/settings-twitch`, { twitchFollowers: !state.listVisible })
                commit('toggle_list_visibility', result.data);
            } catch (error) {
                console.log(error)
            }
        },
        async toggleMessagesVisibility({ commit, state }) {
            try {
                const result = await axios.post(`http://localhost/api/settings-twitch`, { twitchMessagesVisible: !state.twitchMessagesVisible })
                commit('toggle_messages_visibility', result.data);
            } catch (error) {
                console.log(error)
            }
        },
        async fetchTwitchSettings({ commit }) {
            try {
                const result = await axios.get(`http://localhost/api/settings-twitch`);
                commit('set_twitch_settings', result.data);
            } catch (error) {
                console.log(error);
            }
        },
        setTwitchMessagesListener({ commit, state }) {
            if (state.twitchMessageListening) return
            socket.on("twitchMessage-inserted", (twitchMessage) => {
                commit('add_twitch_message', twitchMessage)
            });
            commit('twitch_message_listening', true)
        },
    },
    modules: {
        counter,
        messages,
        settings,
        topics,
        sessions,
    },
})

