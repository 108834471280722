<template>
    <div class="backofficeTopics">
        <CounterButtonsPage class="counter" />

        <CardPanel title="Topics List" class="topics">
            <TopicsListComponent class="topics" />
        </CardPanel>

        <CardPanel title="Add Topic" class="add">
            <UploadTopicComponent class="add-topic" />
        </CardPanel>
    </div>
</template>

<script>
import CardPanel from '@/components/CardPanel'
import CounterButtonsPage from '@/views/CounterButtonsPage'
import TopicsListComponent from '@/components/TopicsListComponent.vue'
import UploadTopicComponent from '@/components/UploadTopicComponent.vue'
import axios from "axios"

export default {
    name: 'BackofficeTopics',
    components: {
        CardPanel,
        CounterButtonsPage,
        TopicsListComponent,
        UploadTopicComponent,
    },
    methods: {
        toggleOrientationSetting() {
            let value = 'normal'
            if (this.orientationValue == 'normal') {
                value = 'vertical'
            }
            axios.post(`${process.env.VUE_APP_BACKEND_URL}/settings`, {
                name: 'orientation', value
            });
        },
    },
    computed: {
        orientationSetting() {
            return this.$store.getters['settings/name']('orientation')
        },
        orientationValue() {
            if (this.orientationSetting == null) {
                return 'normal'
            }
            return this.orientationSetting.value
        },
    },
    mounted() {
        this.$store.dispatch('settings/fetch', 'orientation')
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.backofficeTopics {
    padding: 2rem;

    display: grid;
    gap: 2rem;
    justify-content: center;
    grid-template-columns: max-content minmax(max-content, 900px);
    grid-template-rows: min-content 1fr;
    grid-template-areas:
        "counter topics"
        "add topics";

    width: 100%;

    .add {
        grid-area: add;
        height: fit-content;
    }

    .counter {
        grid-area: counter;
    }

    .topics {
        grid-area: topics;
        width: 100%;
        height: 100%;
        margin: auto;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 400px;
        }
    }
}
</style>
