<template>
    <button class="buttonElement" :type="type" @click.stop.capture @mousedown="$emit('click', $event)">
        <Icon v-if="icon" :icon="icon" class="icon" />
        <slot></slot>
    </button>
</template>

<script>
import Icon from "@/components/Icon.vue"
export default {
    components: {
        Icon,
    },
    props: {
        icon: {
            default: null,
        },
        type: {
            default: 'submit',
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";

.buttonElement {
    border: 1px solid hsl(from white h s l / .3);
    background-color: hsl(from white h s l / 0);
    border-radius: 5px;
    padding: 0 .5em;
    background-color: $bubble-color;
    color: white;
    padding: 0.5rem .5rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    transition: .1s ease-out;
    transition-property: background-color, border;

    &:hover {
        background: $bubble-color;
        cursor: pointer;
        border-color: hsl(from white h s l / 1);
    }

    &.toggle {
        &::before {
            color: white;
            content: '';
            height: 8px;
            width: 8px;
            border-radius: 8px;
            margin: 0 .5em 0 0;
            background: currentColor;
        }

        &.active {
            &::before {
                color: $color-red;
                filter: drop-shadow(0 0 2px currentColor) drop-shadow(0 0 6px currentColor);
            }
        }
    }

    &.delete {
        grid-area: delete;
        color: white;

        &:hover {
            --icon-color: #{$color-red};
            color: $color-red;
            border-color: $color-red;
        }
    }

    .icon {
        min-width: 20px;
        min-height: 20px;
        width: 20px;
        height: 20px;
    }
}
</style>
