<template>
    <div class="messages">
        <CounterButtonsPage class="messages_counter" />

        <MessagesComponent class="messages_messages" />

    </div>
</template>

<script>
import axios from 'axios'
import { socket } from '@/socket';

import CounterButtonsPage from "@/views/CounterButtonsPage";

import MessagesComponent from "@/components/MessagesComponent";

export default {
    components: {
        CounterButtonsPage,
        MessagesComponent,
    },
    data() {
        return {
            newMessage: "",
            live: false,
        }
    },
    methods: {
        async addMessage() {
            try {
                const response = await axios.post(
                    `${process.env.VUE_APP_BACKEND_URL}/messages`,
                    { message: this.newMessage },
                    { headers: this.headers },
                );
                this.$emit('message-inserted', { message: this.newMessage });
                this.newMessage = ""
                return response.data
            } catch (error) {
                console.error(error);
            }
        },
        toogleLive() {
            this.live = !this.live
        },
        async deleteMessage() {
            await axios.delete(
                `${process.env.VUE_APP_BACKEND_URL}/messages/live`,
                { headers: this.headers },
            );
        },
        async activateMessage(message) {
            await axios.put(
                `${process.env.VUE_APP_BACKEND_URL}/messages/live/${message.id}`,
                null,
                { headers: this.headers },
            );
            this.$emit('message-inserted', { message: message.text });
        },
        async updateMessage() {
            if (!this.message) {
                return
            }
            await axios.patch(
                `${process.env.VUE_APP_BACKEND_URL}/messages/${this.message.id}`,
                { message: this.newMessage },
                { headers: this.headers },
            );
        },
    },
    computed: {
        headers() {
            let headers = {}
            if (this.session) {
                headers.session = this.session.session_id
            }
            return headers
        },
        message() {
            return this.$store.getters['messages/liveMessage']
            //return this.$store.state.messages.message
        },
        messages() {
            return this.$store.state.messages.all
        },
        session() {
            return this.$store.state.sessions.current
        },
    },
    watch: {
        async live() {
            if (!this.live) {
                this.newMessage = ""
                return
            }
            if (this.message) {
                this.newMessage = this.message.message.replaceAll("<br>", "\n")
            } else {
                let message = await this.addMessage()
                this.activateMessage(message)
            }
        },
        message(n, o) {
            if (!this.live) {
                return
            }
            if (n != null && o != null && n.id == o.id) {
                return
            }
            this.newMessage = this.message.message.replaceAll("<br>", "\n")
        },
        newMessage() {
            if (!this.live) {
                return
            }
            this.updateMessage()
        },
    },
    mounted() {
        socket.on("message-removed", () => {
            this.message = null
        });
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";

.messages {
    padding: 2rem;
    gap: 2rem;
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-template-rows: min-content min-content;
    grid-template-areas:
        "counter messages"
        ". messages";
    ;

    &_counter {
        grid-area: counter;
    }

    &_messages {
        grid-area: messages;
    }

    &_upload {
        grid-area: newMessage;
    }

    &_active {
        grid-area: active;
        padding: 1rem;
        text-align: center;
    }

    form {
        display: flex;
        flex-direction: column;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: .5rem;
        text-align: center;

        li {
            padding: 1rem;
            border: 2px solid;

            &.active {
                border-color: green;
            }

            &.live {
                display: none;
            }
        }

    }

    .buttons {
        display: flex;

        >* {
            flex-grow: 1;
            flex-basis: 0;
        }
    }

    textarea {
        height: 200px;
        border: 2px solid white;
        outline: none;

        &.active {
            border-color: $color-red;
        }
    }

}
</style>
