<template>
    <span class="counter" :class="{ alert: counter > totalTime }">{{ minutes }}:{{ seconds }}</span>
</template>

<script>
import moment from "moment"

export default {
    name: 'CounterPage',
    data() {
        return {}
    },
    computed: {
        counter() {
            return this.$store.state.counter.counter
        },
        moment() {
            return moment.duration(this.counter)
        },
        minutes() {
            const minutes = this.moment.minutes()
            return minutes < 10 ? "0" + minutes : minutes;
        },
        seconds() {
            const seconds = this.moment.seconds()
            return seconds < 10 ? "0" + seconds : seconds;
        },
        totalTime() {
            return this.$store.getters['totalTime']
        }
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";

.counter {
    font-size: calc(var(--unit) * 13);
    font-family: 'Fira Code', monospace;
    line-height: 1;
    color: white;
    margin: 0;

    &.alert {
        color: $color-red;
    }
}
</style>
