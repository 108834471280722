import axios from 'axios';
import { socket } from "@/socket.js";

const messages = {
    namespaced: true,

    state: {
        all: [],
        liveId: 0,
    },
    getters: {
        liveMessage(state) {
            return state.all.find(({ id }) => id == state.liveId)
        },
    },

    mutations: {
        add(state, messages) {
            for (let message of messages) {
                message.id = parseInt(message.id)
                let index = state.all.findIndex(({ id }) => {
                    return id == message.id
                })
                if (index < 0) {
                    state.all.push(message)
                } else {
                    state.all.splice(index, 1, message);
                }
            }
        },
        clear(state) {
            state.all = []
        },
        set(state, messageId) {
            state.liveId = messageId
        },
        remove(state, messageId) {
            let index = state.all.findIndex(({ id }) => {
                return id == messageId
            })
            if (index < 0) {
                return
            }
            state.all.splice(index, 1);
        },
    },

    actions: {
        async fetch({ commit, rootState }) {
            commit('clear')
            try {
                const response = await axios.get(
                    `${process.env.VUE_APP_BACKEND_URL}/messages`,
                    {
                        headers: {
                            session: rootState.sessions.current.session_id
                        },
                    }
                );
                commit('add', response.data)
            } catch (error) {
                commit('set', null)
                console.log(error)
            }
        },

        async listenCreated({ commit }, sessionId) {
            socket.on(`${sessionId}:message-created`, (message) => {
                commit('add', [message])
            });
        },

        async listenLive({ commit }, sessionId) {
            socket.on(`${sessionId}:message-live`, (messageId) => {
                commit('set', messageId)
            });
        },

        async listenUpdated({ commit }, sessionId) {
            socket.on(`${sessionId}:message-updated`, (message) => {
                commit('add', [message])
            });
        },

        async listenRemoved({ commit }, sessionId) {
            socket.on(`${sessionId}:message-removed`, (message) => {
                commit('remove', message.id)
            });
        },
    },
}

export default messages;

// socket.off("message-inserted")
// socket.off("message-removed")
