<template>
    <div class="backoffice" v-if="session">
        <!-- <ul class="tabs">
            <li><router-link :to="{ name: 'Home' }" @click="clearSession">Exit</router-link></li>
</ul> -->

        <router-view class="backoffice_view"></router-view>
    </div>

    <form v-else @submit.prevent="getSession" class="backoffice_pre">
        <input type="text" v-model="id" placeholder="Session ID">
        <Button type="submit" class="join">Join</Button>
        <Button @click.capture.stop="createSession" type="button" class="create">
            Start new Session
        </Button>
    </form>
</template>

<script>
import { getCookie } from "@/cookies.js"

import Button from "@/components/ButtonComponent.vue"

export default {
    name: 'BackofficePage',
    components: {
        Button
    },
    data() {
        return {
            id: "",
        }
    },
    methods: {
        createSession() {
            this.$store.dispatch("sessions/create")
        },
        clearSession() {
            this.$store.dispatch("sessions/remove")
        },
        getSession() {
            if (this.id == "") {
                return
            }
            this.$store.dispatch('sessions/fetch', this.id.toUpperCase());
        },
    },
    computed: {
        session() {
            return this.$store.state.sessions.current
        },
    },
    watch: {
        session() {
            if (this.session == null) {
                return
            }
            this.id = this.session.session_id
        },
    },
    mounted() {
        if (this.session != null) {
            return
        }
        let sessionId = getCookie('session')
        if (sessionId) {
            this.$store.dispatch("sessions/fetch", sessionId)
        }
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";

form {
    width: 100%;
    height: 100dvh;
    display: grid;
    grid-template-rows: 1fr repeat(4, min-content) 1fr;
    gap: .5rem;

    input,
    button {
        max-width: 200px;
        width: 90%;
        margin: auto;
        min-height: 3rem;
        border: none;
        outline: none;
        text-align: center;
    }

    input {
        grid-row: 2;
        text-transform: uppercase;
        border-radius: 5px;
    }

    .join {
        grid-row: 3;
    }

    .create {
        grid-row: 4;
    }

}

.backoffice {
    width: 100%;
    height: 100vh;
    background: $bubble-two-color;
    border-radius: 20px;
    color: $text-color;
    display: flex;
    flex-direction: column;

    .tabs {
        grid-area: tabs;
        display: flex;
        margin: 0;
        list-style: none;
        flex-shrink: 0;

        li {
            flex-basis: 0;
            flex-grow: 1;
            text-align: center;

            a {
                text-decoration: none;
                color: $text-color;
                padding: 1rem;
                display: block;

                &:hover {
                    background-color: rgba(white, .1);
                }

                &.router-link-exact-active {
                    color: white;
                    background-color: rgba(white, .15);
                }
            }
        }
    }

    .backoffice_view {
        flex-grow: 1;
        overflow: auto;
    }
}
</style>
