import { socket } from "@/socket.js";

var isLiveTimeout = null;

const counter = {
    namespaced: true,

    state: {
        counter: 0,
        isLive: false,
    },
    getters: {},

    mutations: {
        set(state, counter) {
            state.counter = counter;
        },
        setLive(state, live) {
            state.isLive = live
        },
    },

    actions: {
        async listen({ commit }, sessionId) {
            socket.on(`${sessionId}:counter`, (counter) => {
                commit('set', counter)
                commit('setLive', counter != 0)
                clearTimeout(isLiveTimeout)
                isLiveTimeout = setTimeout(() => {
                    commit('setLive', false)
                }, 500);
            });
        },
    },
}

export default counter;
