<template>
    <svg class="IconComponent">
        <use :xlink:href="href" />
    </svg>
</template>

<script>
import ICON from "@/assets/icons.svg";

export default {
    name: "IconComponent",
    props: {
        icon: { required: true },
    },
    computed: {
        href() {
            return `${ICON}#${this.icon}`
        },
    },
}
</script>

<style lang="scss" scoped>
.IconComponent {
    --_icon-color: var(--icon-color, white);
    fill: var(--_icon-color);
    min-width: 0;
    min-height: 0;
    max-width: 100%;
    max-height: 100%;
    height: auto;
}
</style>
