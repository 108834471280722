import axios from 'axios';
import { socket } from "@/socket.js";

function setupTopic(topic) {
    topic.totalMillis = (topic.minutes * 60 + topic.seconds) * 1000
    return topic
}

const messages = {
    namespaced: true,

    state: {
        all: [],
    },

    getters: {
        active(state, getters, rootState) {
            let pastTopicsTime = 0
            let pastTopics = []
            let activeTopic = null
            const counter = rootState.counter.counter
            if (counter != 0) {
                for (let topic of state.all) {
                    const topicTotalTime = pastTopicsTime + topic.totalMillis
                    if (topicTotalTime > counter) {
                        activeTopic = topic
                        break
                    }
                    pastTopics.push(topic.id)
                    pastTopicsTime = topicTotalTime
                }
            }
            return {
                pastTopicsTime,
                pastTopics,
                topicTime: counter - pastTopicsTime,
                topic: activeTopic,
            }
        },
    },

    mutations: {
        add(state, topic) {
            setupTopic(topic)
            state.all.push(topic)
        },

        addTopics(state, topics) {
            for (let topic of topics) {
                setupTopic(topic)
            }
            state.all = topics;
        },

        clear(state) {
            state.all = []
        },

        delete(state, id) {
            const index = state.all.findIndex(topic => id == topic.id)
            if (index < 0) {
                return false
            }
            state.all.splice(index, 1)
            return true
        },

        update(state, topic) {
            setupTopic(topic)
            const index = state.all.findIndex(({ id }) => id == topic.id)
            if (index < 0) {
                state.all.push(topic)
            } else {
                state.all.splice(index, 1, topic)
            }
        }
    },

    actions: {
        async listenCreated({ commit }, sessionId) {
            socket.on(`${sessionId}:topic-created`, (topic) => {
                commit('add', topic)
            });
        },

        async listenDeleted({ commit }, sessionId) {
            socket.on(`${sessionId}:topic-deleted`, (id) => {
                commit('delete', id)
            });
        },

        async listenUpdated({ commit }, sessionId) {
            socket.on(`${sessionId}:topic-updated`, (topic) => {
                commit('update', topic)
            });
        },

        async fetchAll({ commit, rootState }) {
            commit('clear')
            const session = rootState.sessions.current
            let headers = {}
            if (session) {
                headers.session = session.session_id
            }
            try {
                const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/topics`, { headers });
                commit('addTopics', response.data)
            } catch (error) {
                console.log(error)
            }
        },
    },
}

export default messages;
