<template>
    <div class="home">
        <router-link :to="{ name: 'TimestampPage' }" class="transition">
            TIMER
        </router-link>
        <router-link :to="{ name: 'Backoffice' }" class="backoffice">
            CONTROLLER
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'HomePage',

}
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";

.home {
    width: 100vw;
    height: 100svh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    padding: 2rem;
    background: black;
}

a {
    --background-color: #{$bubble-two-color};
    --text-color: var(--text-color, #{$text-color});

    --_background-color: var(--background-color, #{$bubble-two-color});
    --_text-color: var(--text-color, #{$text-color});
    border: none;
    padding: 0.5rem 1rem;
    text-align: center;
    text-decoration: none;
    font-size: 4vw;
    border-radius: 5px;

    display: grid;
    align-items: center;

    color: var(--_text-color);
    background-color: var(--_background-color);

    transition: .2s ease-out;
    transition-property: background-color, color, border-color;

    border: 2px solid var(--_text-color);

    will-change: background-color, color;

    &:hover {
        --_background-color: hsl(from var(--background-color) h s calc(l + 5));
        --_text-color: hsl(from var(--text-color) h calc(s + 5) calc(l - 5));
    }

    &:focus {
        --_background-color: hsl(from var(--background-color) h s calc(l + 2));
        --_text-color: hsl(from var(--text-color) h s calc(l + 50));
    }
}

.transition {
    --text-color: #aaaaaa;
}

.backoffice {
    --text-color: #{$color-red};
}
</style>
