<template>
    <form class="messageComponent" @submit.prevent :class="{ active }">
        <textarea type="text" ref="textarea" v-model="text" placeholder="Write here..." :style="style">
        </textarea>
        <ButtonComponent type="submit" class="toggle" @click.prevent="toggle" v-text="active ? 'Hide' : 'Show'"
            :class="{ active }" />
        <ButtonComponent type="submit" class="delete" @click.prevent="deleteMessage" icon="delete" />
    </form>
</template>

<script>
import axios from 'axios'

import ButtonComponent from "@/components/ButtonComponent"

export default {
    name: 'MessageComponent',

    components: {
        ButtonComponent,
    },

    props: {
        message: { required: true },
    },

    data() {
        return {
            text: this.message.message.replaceAll("<br>", "\n"),
            height: "3.25rem",
        }
    },

    methods: {
        async toggle() {
            await axios.put(
                `${process.env.VUE_APP_BACKEND_URL}/messages/live/${this.message.id}`,
                null,
                { headers: this.headers },
            );
        },
        async deleteMessage() {
            try {
                await axios.delete(
                    `${process.env.VUE_APP_BACKEND_URL}/messages/${this.message.id}`,
                    { headers: this.headers },
                );
            } catch (error) {
                console.error('Error deleting topic:', error);
            }
        },
    },
    computed: {
        active() {
            const liveMessage = this.$store.getters['messages/liveMessage']
            if (liveMessage == null) {
                return false
            }
            return liveMessage.id == this.message.id
        },
        style() {
            return {
                height: this.height,
            }
        },
        headers() {
            let headers = {}
            if (this.$store.state.sessions.current) {
                headers.session = this.$store.state.sessions.current.session_id
            }
            return headers
        },
    },

    watch: {
        text() {
            axios.patch(
                `${process.env.VUE_APP_BACKEND_URL}/messages/${this.message.id}`,
                { message: this.text },
                { headers: this.headers },
            )
            this.height = 0
            this.$nextTick().then(() => {
                this.height = `${this.$refs.textarea.scrollHeight + 5}px`
            })

        },
    },

    mounted() {
        console.log("MOUNTED", this.message.id);
        this.$nextTick().then(() => {
            this.height = `${this.$refs.textarea.scrollHeight + 5}px`
        })
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";

.messageComponent {
    border-radius: 5px;
    background-color: $bubble-color;
    transition: .1s ease-out background-color;
    width: 100%;
    padding: .5rem;
    position: relative;
    display: grid;
    gap: .5rem;
    grid-template-columns: 2fr min-content;
    grid-template-areas:
        "message message"
        "add     delete ";

    &.active {
        background-color: $color-red;
    }

    textarea {
        grid-area: message;
        resize: none;
        background-color: black;
        width: 100%;
        color: white;
        padding: 0.375rem 0.75rem;
        border-color: transparent;
        border-radius: .25rem;
        border: 1px solid black;
        outline: none;
        min-height: 5rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &:active,
        &:focus {
            border-color: white;
            box-shadow: 0px 0px 5px 0px white;
        }
    }
}
</style>
