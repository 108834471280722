<template>
    <div class="upload">
        <h2>Your Logo</h2>
        <p>Click to upload</p>
        <label @dragover.prevent="dragging = true" @dragleave.prevent="dragging = false" @drop.prevent="addFile"
            :class="{ dragging }">
            <input type="file" @change="setFile" ref="fileInput">
            <img :src="LOGO" class="logo">
        </label>
        <p class="errorMessage"></p>
    </div>
</template>

<script>
import axios from 'axios'
import { socket } from '@/socket'
const logoSrc = `${process.env.VUE_APP_BACKEND_URL}/logos`

export default {
    name: 'UploadLogo',
    data() {
        return {
            LOGO: "",
            dragging: false,
            file: null
        }
    },
    methods: {
        addFile(event) {
            this.dragging = false
            this.file = event.dataTransfer.files[0]
            this.uploadLogo()
        },
        setFile(event) {
            this.file = event.target.files[0]
            this.uploadLogo()
        },
        async uploadLogo() {
            if (!this.file) {
                return
            }

            const logo = new FormData()
            logo.append('image', this.file)

            try {
                await axios.post(
                    `${process.env.VUE_APP_BACKEND_URL}/logos`,
                    logo,
                    { headers: this.headers },
                )
                this.$emit('logo-inserted', logo);
                this.$refs.fileInput.value = '';
            } catch (error) {
                console.log(error)
            }
        }
    },
    computed: {
        headers() {
            let headers = {}
            if (this.session) {
                headers.session = this.session.session_id
            }
            return headers
        },
        src() {
            return `${logoSrc}/${this.session.session_id}`
        },
        session() {
            return this.$store.state.sessions.current
        },
    },
    mounted() {
        this.LOGO = this.src
        socket.on("logo-inserted", async () => {
            this.LOGO = ""
            await fetch(this.src, {
                cache: 'reload',
                mode: 'no-cors',
                headers: this.headers,
            })
            this.LOGO = this.src
        });
    },
    unmounted() {
        socket.off("logo-inserted")
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";

.errorMessage {
    color: $color-red;
    margin: 1rem 0;
}

.upload {
    background: $bubble-color;
    padding: 1rem;
    border-radius: 10px;
    width: auto;
}

label {
    color: $text-color;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    display: flex;
    justify-content: center;

    &:hover {
        color: $text-color_light;
        background-color: $color-blue;
    }

    &.dragging {
        opacity: .5;
    }

    img {
        max-height: 50vh;
        max-width: 100%;
    }

}

input {
    display: none;
}

.logo-btn {
    border: none;
    color: $color-blue;
    padding: 0.5rem 1rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    background-color: $bubble-two-color;
    margin-right: .5rem;
    width: 100%;

    &:hover {
        color: $color-light_blue;
        background: $bubble-color;
    }
}

.logo-input {
    display: none;
    color: $text-color;

    &:hover {
        border-color: $color-blue;
        outline: 0;
        box-shadow: 0 0 0 0.2rem $color-blue;
    }
}
</style>
