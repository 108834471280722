<template>
    <div class="counterPage">
        <Button @mousedown="resetCounter" class="btn-reset" :disabled="counter == 0" icon="first-page">
        </Button>
        <Button @mousedown="startCounter" class="btn-start" v-if="!isLive" icon="play">
        </Button>
        <Button @mousedown="stopCounter" class="btn-stop" v-else icon="pause">
        </Button>
    </div>
</template>

<script>
import axios from 'axios'
import Button from '@/components/ButtonComponent.vue'

export default {
    components: {
        Button,
    },
    data() {
        return {
        }
    },
    methods: {
        async startCounter() {
            try {
                await axios.post(
                    `${process.env.VUE_APP_BACKEND_URL}/counter/start`,
                    null,
                    { headers: this.headers }
                )
            } catch (error) {
                console.log(error)
            }
        },
        async stopCounter() {
            try {
                await axios.post(
                    `${process.env.VUE_APP_BACKEND_URL}/counter/stop`,
                    null,
                    { headers: this.headers }
                )
            } catch (error) {
                console.log(error)
            }
        },
        async resetCounter() {
            try {
                await axios.post(
                    `${process.env.VUE_APP_BACKEND_URL}/counter/reset`,
                    null,
                    { headers: this.headers }
                )
            } catch (error) {
                console.log(error)
            }
        },
    },
    computed: {
        counter() {
            return this.$store.state.counter.counter
        },
        isLive() {
            return this.$store.state.counter.isLive
        },
        headers() {
            let headers = {}
            if (this.session) {
                headers.session = this.session.session_id
            }
            return headers
        },
        session() {
            return this.$store.state.sessions.current
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";

.counterPage {
    display: flex;
    background: $bubble-color;
    width: auto;
    padding: .5rem;
    border-radius: 10px;
    position: relative;
    gap: .5rem;
}

.btn-start {
    --icon-color: var(--color-green);
}

.btn-stop {
    color: $color-red;
    --icon-color: var(--color-red);
}

.btn-reset {
    color: $color-yellow;
}
</style>
