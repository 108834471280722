<template>
    <div class="topics-list-container">
        <h2>Topics</h2>
        <UpdateTopicComponent v-for="topic, index in topics" :topic="topic" :key="topic.id" :index="index + 1">
        </UpdateTopicComponent>

        <ButtonComponent type="submit" class="add-btn" @click.prevent="addTopic">
            Add
        </ButtonComponent>
    </div>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent.vue'
import UpdateTopicComponent from '@/components/UpdateTopicComponent.vue'

import axios from 'axios';

export default {
    name: 'TopicsList',
    components: {
        UpdateTopicComponent,
        ButtonComponent,
    },
    data() {
        return {
            editingTopic: null,
            popupVisible: false,
            name: '',
        }
    },

    methods: {
        async addTopic() {
            try {
                console.log(this.headers);
                await axios.post(
                    `${process.env.VUE_APP_BACKEND_URL}/topics`,
                    { topic: '', minutes: 0, seconds: 0 },
                    { headers: this.headers }
                )
            } catch (error) {
                console.log(error)
            }
        },
        async handleTopicUpdated() {
            this.editingTopic = null;
            this.popupVisible = false;
        },
        closePopup() {
            this.editingTopic = null;
            this.popupVisible = false;
        },
    },

    computed: {
        headers() {
            let headers = {}
            headers.session = this.$store.state.sessions.current.session_id
            return headers
        },
        token() {
            return this.$store.state.token
        },
        topics() {
            return this.$store.state.topics.all
        },
    },
    mounted() {
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";

.topics-list-container {
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    h2 {
        line-height: 1;
        margin: 0;
    }

}

ul {
    list-style-type: none;
}

.buttons {
    transition: background-color 0.3s;
    text-align: center;
    color: $text-color;
    text-decoration: none;
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 1rem;
}

.topics-btn {
    border: none;
    padding: 0.5rem 1rem;
    font-size: 16px;
    border-radius: 5px;
    width: auto;
    cursor: pointer;
}


.btn-edit {
    background-color: $bubble-two-color;
    color: $text-color;
}

.btn-edit:hover {
    background-color: $bubble-two-color_light;
    color: $text-color_light;
}

.btn-delete {
    color: $color-red;
    background-color: $bubble-two-color;
}

.btn-delete:hover {
    background-color: $bubble-two-color_light;
    color: $color-light_red;
}


.popup-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $popup-background;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup {
    background-color: $bubble-two-color;
    padding: 2rem;
    border-radius: 10px;
}

.popup .btn-close {
    margin-top: 10px;
}

.btn-close {
    border: none;
    padding: 0.5rem 1rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    background: $bubble-color;
    margin-right: .5rem;
    color: $color-red;
    width: 100%;
}

.btn-close:hover {
    color: $color-light_red;
    background-color: $bubble-two-color;
}
</style>
