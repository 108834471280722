<template>
    <div class="dashboard" :class="{ live: isLive }">
        <h2>
            Session:
            <span>{{ sessionId }}</span>
            <Button @click.prevent="exitSession">Quit</Button>
        </h2>
        <CounterButtonsPage class="counter" />
        <UploadLogoComponent class="logo" />

        <TimestampPage class="preview" :style="{ '--unit': unitSize }" :skipSession="true" ref="timestamp" />

        <MessagesComponent class="messages" />

        <TopicsListComponent class="topics" />

        <!--
        <MessagesComponent class="message" />

        <div style="display: none">{{ token }}</div>
        <div v-if="!token">
            <a :href="authURL" @click.prevent="openOauth">click</a>
        </div>
        <TwitchFollowersList class="followers" :showButton="showButton" />
        <TwitchMessages class="twitchMessages" :showButton="showButton" />
-->
    </div>
</template>

<script>
import CounterButtonsPage from '@/views/CounterButtonsPage.vue'
import UploadLogoComponent from '@/components/UploadLogoComponent.vue';
import MessagesComponent from '@/components/MessagesComponent.vue';
import TopicsListComponent from '@/components/TopicsListComponent.vue';
import Button from "@/components/ButtonComponent.vue"
// import MessagesComponent from '@/components/MessagesComponent.vue'
// import TwitchFollowersList from '@/components/TwitchFollowersList.vue'
// import TwitchMessages from '@/components/TwitchMessages.vue'
import axios from 'axios';

import TimestampPage from '@/views/TimestampPage.vue'

const API_URL = process.env.VUE_APP_API_URL;

export default {
    name: "BackofficeDashboard",
    components: {
        CounterButtonsPage,
        Button,
        UploadLogoComponent,
        TimestampPage,
        MessagesComponent,
        TopicsListComponent,
        // TwitchFollowersList,
        // TwitchMessages,
    },
    data() {
        return {
            showButton: true,
            unitSize: 0,
        }
    },
    methods: {
        openOauth() {
            let url = new URL(this.authURL)
            url.searchParams.set("client_id", process.env.VUE_APP_TWITCH_CLIENT_ID)
            url.searchParams.set("redirect_uri", process.env.VUE_APP_TWITCH_REDIRECT_URL)
            url.searchParams.set("response_type", "token")
            url.searchParams.set("scope", "moderator:read:followers")

            const authWindow = window.open(url, "twitch", "width=600, height=600")
            authWindow.onunload = () => {
                setTimeout(async () => {
                    if (authWindow.closed) {
                        await axios.get(`${API_URL}twitch/token`)
                    }
                }, 200)
            }
        },
        async getSubscriptions() {
            try {
                const result = await axios.get('http://localhost/api/twitch-subscribers')
                console.log(result)
            } catch (error) {
                console.log(error)
            }
        },
        exitSession() {
            this.$store.dispatch('sessions/remove')
        },
    },
    computed: {
        authURL() {
            return process.env.VUE_APP_TWITCH_AUTH_URL
        },
        isLive() {
            return this.$store.state.counter.isLive
        },
        session() {
            return this.$store.state.sessions.current
        },
        sessionId() {
            if (this.session == null) {
                return ""
            }
            return this.session.session_id
        },
        token() {
            return this.$store.state.token
        },
    },
    mounted() {
        this.$store.dispatch("getTwitchToken")
        this.getSubscriptions()
        console.log(this.$refs.timestamp.$el.clientWidth)
        this.unitSize = ((this.$refs.timestamp.$el.clientWidth - 2) / 100) + "px";
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";

.dashboard {
    padding: 2rem;
    background: $bubble-two-color;
    color: $text-color;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: min-content min-content min-content min-content 1fr;
    gap: 1rem;
    grid-template-areas:
        'title topics messages'
        'preview topics messages'
        'counter topics messages'
        'logo topics messages'
        'twiFollowers topics messages'
        'twiMessages topics messages';

    &.live {
        .preview {
            box-shadow: 0 0 10px 5px $color-red, 0 0 2px $color-red;
            border-color: $color-red;
        }
    }

    h2 {
        grid-area: title;
        margin: 0;
        line-height: 1;
        display: flex;
        align-items: center;
        gap: .5rem;

        span {
            color: var(--color-yellow);
        }

        button {
            width: fit-content;
            margin: 0 0 0 auto;
            height: 1.2rem;
        }
    }

    .counter {
        grid-area: counter;
    }

    .preview {
        max-width: 100%;
        width: 100%;
        grid-area: preview;
        transition: .2s ease-out box-shadow;
        border: 1px solid $text-color;
    }

    .tabs {
        grid-area: tabs;
        display: flex;
        margin: 0;
        list-style: none;

        li {
            flex-basis: 0;
            flex-grow: 1;
            text-align: center;

            a {
                text-decoration: none;
                color: $text-color;

                &.router-link-exact-active {
                    color: white;
                }
            }
        }
    }

    .backoffice-router:visited {
        color: $text-color;
        text-decoration: none;
    }

    .backoffice-router:link {
        color: $text-color;
        text-decoration: none;
    }

    .logo {
        grid-area: logo;
        width: auto;
    }

    .messages {
        grid-area: messages;
        margin: 0;
    }

    .topics {
        grid-area: topics;
    }


    .followers {
        grid-area: twiFollowers;
    }

    .twitchMessages {
        grid-area: twiMessages;
        background: $bubble-color;
        width: 100%;
        padding: 1rem;
        border-radius: 10px;
    }

}
</style>
