<template>
    <div>
        <img :src="src" class="logo" alt="logo">
    </div>
</template>

<script>
import { socket } from '@/socket';

export default {
    name: 'LogoComponent',
    data() {
        return {
            src: "",
        }
    },
    computed: {
        session() {
            return this.$store.state.sessions.current
        },
        logoSrc() {
            return `${process.env.VUE_APP_BACKEND_URL}/logos/${this.session.session_id}`
        },
    },
    mounted() {
        this.src = this.logoSrc
        socket.on("logo-inserted", async () => {
            this.src = ""
            await fetch(this.logoSrc, { cache: 'reload', mode: 'no-cors' })
            this.src = this.logoSrc
        })
    },
    unmounted() {
        socket.off("logo-inserted")
    },
}
</script>

<style lang="scss" scope>
.logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
    min-height: 0;
    min-width: 0;
}
</style>
