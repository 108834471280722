<template>
    <div class="progress" :style="animationDelay" v-if="topic">
        <svg viewBox="0 0 100 100">
            <circle class="progress-background" :r="radius" cx="50" cy="50" fill="transparent" />
            <circle class="progress-ring_circle" :stroke-dasharray="circumference" :stroke-dashoffset="offset"
                :r="radius" cx="50" cy="50" fill="transparent" />
            <circle class="progress-guiding" :cx="guidingCirclePosition.x" :cy="guidingCirclePosition.y"
                fill="rgb(39, 38, 45)" stroke="" stroke-width="1" />
        </svg>
        <div class="timer">{{ formattedTime }}</div>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    name: 'CountdownCircle',

    data() {
        return {
            radius: 45,
            strokeWidth: 3,
        };
    },

    computed: {
        counter() {
            return this.$store.state.counter.counter
        },
        totalTime() {
            return (this.topic.seconds + this.topic.minutes * 60) * 1000
        },
        time() {
            let topicTime = this.counter
            for (let topic of this.topics) {
                if (topic == this.topic) {
                    break;
                }
                topicTime -= (topic.minutes * 60 + topic.seconds) * 1000
            }
            return topicTime
        },
        topic() {
            if (this.topics.length == 0) {
                return null
            }
            let timeCounted = 0
            let topic = null
            for (topic of this.topics) {
                timeCounted += (topic.minutes * 60 + topic.seconds) * 1000
                if (timeCounted > this.counter) {
                    return topic
                }
            }
            return topic
        },
        topics() {
            return this.$store.state.topics.all
        },
        topicDur() {
            return (this.topic.minutes * 60 + this.topic.seconds) * 1000
        },
        animationDelay() {
            return {
                '--delay': `-${this.progress}s`
            }
        },
        circumference() {
            return 2 * Math.PI * this.radius;
        },
        progress() {
            let progress = (this.time / this.totalTime) * 100;
            if (progress > 100) {
                return 100
            }
            return progress
        },
        timeDiff() {
            let duration = - this.counter
            for (let topic of this.topics) {
                duration = (topic.minutes * 60 + topic.seconds) * 1000 + duration
                if (this.topic == topic) {
                    break
                }
            }
            if (duration < 0) {
                duration = 0
            }
            if (duration > this.topicDur) {
                duration = this.topicDur
            }
            return moment.duration(duration)
        },
        guidingCirclePosition() {
            const angle = (this.progress / 100) * 360; // Convert progress to degrees
            const radians = angle * (Math.PI / 180); // Convert degrees to radians
            const x = 50 + this.radius * Math.cos(radians); // Calculate x-coordinate
            const y = 50 + this.radius * Math.sin(radians); // Calculate y-coordinate
            return { x, y };
        },
        offset() {
            return this.circumference * (1 - (this.progress / 100));
        },
        formattedTime() {
            if (this.timeDiff == null) {
                return ""
            }
            let minutes = this.timeDiff.minutes()
            if (minutes < 10) {
                minutes = `0${minutes}`
            }
            let seconds = this.timeDiff.seconds()
            if (seconds < 10) {
                seconds = `0${seconds}`
            }
            return `${minutes}:${seconds}`;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";

@keyframes colorFade {
    0% {
        stroke: $color-green;
    }

    75% {
        stroke: $color-yellow;
    }

    100% {
        stroke: $color-light_red;
    }
}

.progress {
    position: relative;
    display: block;
    line-height: 0;
    height: calc(var(--unit) * 27);
    width: calc(var(--unit) * 27);
    max-height: 100%;
    max-width: 100%;

    .timer {
        font-family: 'Fira Code', monospace;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: fit-content;
        width: fit-content;
        color: $text-color_light;
        font-size: calc(var(--unit) * 5.5);
        font-weight: bold;

    }
}

svg {
    transform: rotate(-90deg);
    width: 100%;
    height: 100%;
}

.progress-ring_circle {
    stroke: $text-color_light;
    transition: stroke-dashoffset 1s linear;
    animation: 100s colorFade linear paused;
    animation-delay: var(--delay);
    animation-fill-mode: both;
    stroke-width: 5;
}

.progress-background {
    stroke: $bubble-color;
    stroke-width: 5;
}

.progress-guiding {
    transition: stroke-dashoffset 1s linear;
    animation: 100s colorFade linear paused;
    animation-delay: var(--delay);
    animation-fill-mode: both;
}
</style>
